<template>
  <div v-if="env == 'DEV'" class="translatsioncontent">
    <b-card>
      <b-col>
        <b-row v-for="translatsion in translatsions" :key="translatsion.key">
          <b-col class="d-flex values">
            <input class="form-control" disabled :value="translatsion.key" />
            <input
              class="form-control"
              @change="updateValue(translatsion.key, $event.target.value, true)"
              :value="translatsion.translation"
            />
          </b-col>
        </b-row>
      </b-col>

      <div class="actions">
        <loader class="loader mb-1" ref="loaderAnimater"></loader>

        <select class="w-30 form-control" v-model="langKey">
          <option v-for="language in Object.keys(languages)" :key="language">
            {{ language }}
          </option>
        </select>

        <div class="d-flex newLang">
          <input
            type="text"
            class="form-control"
            placeholder="Navn på nyt sprog fx. dk"
            v-model="newLangName"
          />
          <b-button class="savebtn" @click="addlanguage" variant="secondary"
            >Tilføre sprog</b-button
          >
        </div>
        <b-button class="savebtn" @click="getJsonFile" variant="primary"
          >Hent oversættelse fil</b-button
        >
      </div>
    </b-card>
  </div>
</template>
<script>
import { BCard, BButton, BRow, BCol } from "bootstrap-vue";
import Index from "../economics/agreementBasis/index.vue";
import ResourceService from "@/services/base/resource.service";
import loader from "@/components/layout/loader.vue";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    Index,
    loader,
  },
  async created() {
    this.service = new ResourceService("Account");
    this.loadLocaleMessages();
  },
  async mounted() {
    await this.fetchDrafts();
  },
  data() {
    return {
      env: process.env.VUE_APP_ENV,
      service: null,
      newLangName: undefined,
      langKey: "en",
      values: {},
    };
  },
  computed: {
    languages() {
      return this.values;
    },
    translatsions() {
      return this.getTranslatsions("", this.values[this.langKey]);
    },
  },
  methods: {
    getTranslatsions(prefix, list) {
      let cvs = [];
      for (const [key, value] of Object.entries(list)) {
        let id = prefix != "" ? prefix + "." + key : key;
        if (typeof value == typeof {}) {
          cvs.push.apply(cvs, this.getTranslatsions(id, value));
        } else {
          cvs.push({ key: id, translation: value });
        }
      }
      return cvs;
    },
    loadLocaleMessages() {
      const locales = require.context(
        "@/libs/i18n/locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
      );
      const messages = {};
      locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const locale = matched[1];
          messages[locale] = locales(key);
        }
      });
      this.values = messages;
    },
    async updateValue(key, value, safe) {
      let keys = key.split(".");
      let translation = this.values[this.langKey];
      let propKey = "";
      keys.forEach((element) => {
        let result = this.getTranslatsion(element, translation);
        if (typeof result == typeof {}) {
          translation = result;
        }
        propKey = element;
      });
      translation[propKey] = value;
      if (safe) await this.saveDraft();
    },
    getTranslatsion(key, value) {
      return value[key];
    },
    getJsonFile() {
      var a = document.createElement("a");
      var file = new Blob([JSON.stringify(this.values[this.langKey])], {
        type: "appliaction/json",
      });
      a.href = URL.createObjectURL(file);
      a.download = this.langKey + ".json";
      a.click();
    },
    addlanguage() {
      this.values[this.newLangName] = JSON.parse(
        JSON.stringify(this.values[this.langKey])
      );
      this.langKey = this.newLangName;
      this.newLangName = undefined;
    },
    async saveDraft() {
      this.$refs.loaderAnimater.loading();

      let json = JSON.stringify(this.values[this.langKey]);
      await this.service.createFromUrl(`/SaveLanguageDraft/${this.langKey}`, {
        jsonString: json,
      });
      this.$refs.loaderAnimater.loadCompelete();
    },
    async fetchDrafts() {
      this.$refs.loaderAnimater.loading();

      let respone = await this.service.single(
        `GetLanguageDraft/${this.langKey}`
      );
      let list = respone.data;
      if (list == null || list.length == 0) {
        this.$refs.loaderAnimater.loadCompelete();

        return;
      }

      list.forEach((element) => {
        let jsonParsed = JSON.parse(element.jsonString);
        this.values[element.key] = jsonParsed;
      });

      this.$refs.loaderAnimater.loadCompelete();
    },
  },
};
</script>
<style lang="scss" scoped>
.newLang {
  > input {
    width: 60%;
    margin-top: 5px;
  }
  flex-wrap: wrap;
  justify-content: space-between;
}
.translatsioncontent {
  display: flex;
  .card {
    width: 100%;
    .card-body {
      .col {
        width: 75%;
      }
    }
  }
}
.actions {
  width: 20%;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 5%;
  top: 20px;
}
.savebtn {
  margin-top: 5px;
}

.values {
  margin-bottom: 5px;

  > input {
    margin-right: 5px;
  }
}
</style>